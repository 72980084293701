/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { AppConfig } from './app-config';

/**
 * V2 Endpoints
 *
 * @export
 * @class EndpointV2
 */
/* eslint-disable jsdoc/require-jsdoc */
export class EndpointV2 {
  public static readonly ACCEPT_EULA = `${AppConfig.API_V2_ROOT}/eula/accept`;

  public static readonly INTEGRATION_META_ROOT = `${AppConfig.API_V2_ROOT}/meta`;
  public static readonly INTEGRATION_META_SUGGESTIONS = `${EndpointV2.INTEGRATION_META_ROOT}/suggestions`;
  public static INTEGRATION_ENTITY_ROOT(integration: string, entity: string) {
    return `${EndpointV2.INTEGRATION_META_ROOT}/integration/${integration}/entity/${entity}`;
  }
  public static INTEGRATION_ENTITY_FILTER_SUGGESTION(integration: string, entity: string) {
    return `${EndpointV2.INTEGRATION_ENTITY_ROOT(integration, entity)}/suggest`;
  }
  public static readonly BULK_ATTRIBUTES = `${EndpointV2.INTEGRATION_META_ROOT}/attributes`;
  public static INTEGRATION_ENTITY_ATTRIBUTES(integration: string, entity: string, ignoreCrossCategory: boolean) {
    // eslint-disable-next-line max-len
    return `${EndpointV2.INTEGRATION_ENTITY_ROOT(
      integration,
      entity,
    )}/attributes?ignore_cross_entity_data_in_attribute=${ignoreCrossCategory}&ignore_struct_element_attributes=true`;
  }

  public static readonly AUTOMATIONS = `${AppConfig.API_V2_ROOT}/automations`;
  public static readonly AUTOMATION_TEMPLATES = `${EndpointV2.AUTOMATIONS}/templates`;
  public static readonly AUTOMATION_TEMPLATES_V3 = `${EndpointV2.AUTOMATIONS}/v3/templates`;
  public static readonly AUTOMATION_TEMPLATE_DETAILS = (integration: string, entity: string, name: string) => {
    return `${EndpointV2.AUTOMATION_TEMPLATES}/integration/${integration}/entity/${entity}/name/${name}`;
  };
  public static readonly AUTOMATION_TEMPLATE_DETAILS_V3 = (integration: string, entity: string, name: string) => {
    return `${EndpointV2.AUTOMATION_TEMPLATES_V3}/integration/${integration}/entity/${entity}/name/${name}`;
  };

  public static readonly AUTOMATION_CONNECTION = `${AppConfig.API_V2_ROOT}/services`;
  public static readonly AUTOMATION_ACTION_METHOD = `${EndpointV2.AUTOMATION_CONNECTION}/actions`;

  public static readonly ORG_ROOT = `${AppConfig.API_V2_ROOT}/org`;

  public static readonly REPORTS = `${AppConfig.API_V2_ROOT}/reports`;
  public static readonly REPORTS_PREVIEW = `${EndpointV2.REPORTS}/preview`;
  public static REPORT_ID = (id: string) => `${EndpointV2.REPORTS}/${id}`;
  public static REPORT_ID_PREVIEW = (id: string) => `${EndpointV2.REPORT_ID(id)}/preview`;
  public static REPORT_ID_DOWNLOAD = (id: string) => `${EndpointV2.REPORTS}/tracking/${id}/download`;
  public static REPORT_ID_GENERATE = (id: string) => `${EndpointV2.REPORT_ID_DOWNLOAD(id)}/generate`;

  public static readonly CONTENT_ROOT = `${AppConfig.API_V2_ROOT}/content`;
  public static readonly CONTENT_SEARCH = `${EndpointV2.CONTENT_ROOT}/search`;
  public static readonly CONTENT_MULTI_SEARCH = `${EndpointV2.CONTENT_SEARCH}/bulk`;

  public static readonly REPORTS_SEARCH = `${EndpointV2.REPORTS}/search`;

  public static readonly WIDGET_ROOT_V2 = `${AppConfig.API_V2_ROOT}/widgets`;
  public static readonly WIDGET_TREND_PREVIEW_V2 = `${EndpointV2.WIDGET_ROOT_V2}/trend/preview`;
  public static WIDGET_ID = (id: string) => `${EndpointV2.WIDGET_ROOT_V2}/${id}`;
  public static WIDGET_TRENDS = (id: string) => `${EndpointV2.WIDGET_ID(id)}/trends`;

  public static readonly ASYNC_QUERY = `${AppConfig.API_V2_ROOT}/async/query`;
  public static readonly ASYNC_TREND = `${EndpointV2.ASYNC_QUERY}/trend`;
  public static readonly QUERY_ID_BY_WIDGET_IDS = `${EndpointV2.ASYNC_QUERY}/widget/trends`;
  public static readonly QUERY_ID_BY_TREND_DEFINITIONS = `${EndpointV2.ASYNC_TREND}/bulk`;
  public static readonly WIDGET_DATA_BY_QUERY_ID = (id: string) => `${EndpointV2.ASYNC_TREND}/${id}`;
  public static readonly CANCEL_POLLING_BY_QUERY_ID = (id: string) => `${EndpointV2.WIDGET_DATA_BY_QUERY_ID(id)}/cancel`;

  public static WIDGETS_DEFAULT_ATTRIBUTES = (integration: string, entity: string, ignoreCrossEntity: boolean) => {
    // eslint-disable-next-line max-len
    return `${EndpointV2.WIDGET_ROOT_V2}/data/defaultattributes/${integration}/${entity}?ignore_cross_entity_data_in_attribute=${ignoreCrossEntity}`;
  };

  public static readonly WIDGET_TEMPLATE_ROOT = `${EndpointV2.WIDGET_ROOT_V2}/templates`;
  public static WIDGET_TEMPLATE_BY_INTEGRATION_ENTITY_NAME = (integration: string, entity: string, name: string) =>
    `${EndpointV2.WIDGET_TEMPLATE_ROOT}/integration/${integration}/entity/${entity}/name/${name}`;

  public static readonly DASHBOARD_ROOT = `${AppConfig.API_V2_ROOT}/dashboards`;
  public static DASHBOARD_ID = (id: string) => `${EndpointV2.DASHBOARD_ROOT}/${id}`;

  public static DASHBOARD_LAYOUT = (id: string) => `${EndpointV2.DASHBOARD_ID(id)}/layout`;

  public static DASHBOARD_CLONE = (id: string, name: string) =>
    `${EndpointV2.DASHBOARD_ID(id)}/clone?dashboard_name=${encodeURIComponent(name)}`;

  public static DASHBOARD_THUMBNAIL = (id: string, widgetId: string) => `${EndpointV2.DASHBOARD_ID(id)}/thumbnail?widget_id=${widgetId}`;

  public static readonly DASHBOARD_WIDGETS = `${EndpointV2.DASHBOARD_ROOT}/widgets`;

  public static readonly STANDARD_DASHBOARD_WIDGETS_PREVIEW = `${EndpointV2.WIDGET_ROOT_V2}/standard/preview`;

  public static STANDARD_DASHBOARD_BY_TYPE = (dashboardType: string) => `${EndpointV2.DASHBOARD_ROOT}/standard/${dashboardType}`;

  public static readonly DASHBOARD_SEARCH = `${EndpointV2.DASHBOARD_ROOT}/search`;
  public static DASHBOARD_EXPORT = (dashboardId: string) => `${EndpointV2.DASHBOARD_ROOT}/${dashboardId}/template/export`;

  public static readonly TEMPLATES_ROOT = `${AppConfig.API_V2_ROOT}/templates`;
  public static readonly TEMPLATE_DASHBOARD = `${EndpointV2.TEMPLATES_ROOT}/dashboard`;
  public static TEMPLATE_DASHBOARD_BY_NAME = (name: string) => `${EndpointV2.TEMPLATE_DASHBOARD}/${name}`;
  public static readonly TEMPLATE_DASHBOARD_IMPORT = `${EndpointV2.TEMPLATES_ROOT}/dashboard/import`;

  // Apps Dashboard
  public static readonly APPS = `${AppConfig.API_V2_ROOT}/apps`;
  public static readonly NETWORK_INSIGHTS = `${EndpointV2.APPS}/network/insight`;
  public static readonly NETWORK_INSIGHTS_SEARCH = `${EndpointV2.NETWORK_INSIGHTS}/search`;
  public static readonly GET_CRASH_GROUPS = `${EndpointV2.APPS}/crash/groups`;
  public static readonly GET_HE_GROUPS = `${EndpointV2.APPS}/handledexception/groups`;

  // In-product trial
  public static readonly TRIAL_ROOT = `${EndpointV2.ORG_ROOT}/trial`;
  public static readonly TRIAL_USER_CONTACT_DETAILS_IN_PRODUCT_TRIAL = `${EndpointV2.TRIAL_ROOT}/contactdetails`;
  public static readonly START_IN_PRODUCT_TRIAL = (serviceType: string) => `${EndpointV2.TRIAL_ROOT}/start?service_type=${serviceType}`;

  // Custom attributes
  public static readonly STRUCTMAP_IDENTIFIERS = `${EndpointV2.INTEGRATION_META_ROOT}/structmap/identifiers`;
  public static readonly STRUCTMAP_IDENTIFIER_ATTRIBUTES = `${EndpointV2.INTEGRATION_META_ROOT}/structmap/identifier/attributes`;
}
