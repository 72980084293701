/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClrIconModule } from '@clr/angular';

/**
 * StarRatingComponent
 * @export
 * @class StarRatingComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'dpa-star-rating',
  templateUrl: 'star-rating.component.html',
  styleUrl: 'star-rating.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ClrIconModule],
})
export class StarRatingComponent implements OnInit {
  @Input({ required: true }) public rating: number;
  @Input() public iconSize: number;
  @Input() public maxRating = 5;
  @Input() public isCompactGap: boolean = false;
  @Input() public isReadOnly: boolean = false;
  @Output() public ratingChange: EventEmitter<number> = new EventEmitter<number>();

  public range: number[] = [];

  /**
   * ngOnInit
   * @memberof StarRatingComponent
   */
  public ngOnInit() {
    this.updateMaxRatingCount();
  }

  /**
   * updateMaxRatingCount
   * @memberof StarRatingComponent
   */
  public updateMaxRatingCount() {
    this.range = Array.from({ length: this.maxRating }, (_, i) => i + 1);
  }

  /**
   * onStarClick
   * @param {number} rating
   * @memberof StarRatingComponent
   */
  public onStarClick(rating: number) {
    this.ratingChange.emit(rating);
  }
}
