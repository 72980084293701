/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { ContentType } from '@ws1c/intelligence-common/models';
import { DownloadService } from '@ws1c/intelligence-common/services/download.service';
import { CrlfFormatter, LANGUAGE_FILE_EXTENSIONS, Template } from '@ws1c/intelligence-models';

/**
 * CodePreviewComponent
 * @export
 * @class CodePreviewComponent
 * @implements {OnChanges}
 */
@Component({
  selector: 'dpa-code-preview',
  templateUrl: 'code-preview.component.html',
  styleUrls: ['code-preview.component.scss'],
})
export class CodePreviewComponent implements OnChanges {
  @Input() public code: string;
  @Input() public fileName: string;
  @Input() public isDeployEnabled: boolean = false;
  @Input() public isDownloadEnabled: boolean = false;
  @Input() public language: string;
  @Input() public showHeading: boolean = true;
  @Output() public deployTemplate: EventEmitter<Template> = new EventEmitter<Template>();

  public readonly ContentType = ContentType;
  public linesOfCode: string[] = [];

  /**
   * @param {DownloadService} downloadService
   * @memberof CodePreviewComponent
   */
  constructor(private downloadService: DownloadService) {}

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof CodePreviewComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (!changes.code || !this.code) {
      this.linesOfCode = [];
      return;
    }

    this.linesOfCode = CrlfFormatter.ensureCRLF(this.code).split(CrlfFormatter.LF);
  }

  /**
   * onDownloadClick
   * @memberof CodePreviewComponent
   */
  public onDownloadClick() {
    const fileFormat = LANGUAGE_FILE_EXTENSIONS[this.language.toLowerCase()];
    this.downloadService.downloadCodeInGivenFileFormat(this.linesOfCode, this.fileName, fileFormat);
  }

  /**
   * onDeployClick
   * @memberof CodePreviewComponent
   */
  public onDeployClick() {
    this.deployTemplate.emit();
  }
}
