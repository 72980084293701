/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

/**
 * Common Feature Controls
 *
 * @export
 * @enum {string}
 */
export enum FeatureControl {
  // WS1 INTELLIGENCE SKU
  AUTOMATION_ENABLED = 'automation.enabled',
  AUTOMATION_BATCH_MODE_ENABLED = 'automation.batch.mode.enabled',
  AUTOMATION_CUSTOM_CONNECTOR_ENABLED = 'automation.customconnector.enabled',
  AUTOMATION_EVALUATION_TRIGGERS_ENABLED = 'automation.evaluation.triggers.enabled',
  AUTOMATION_REPEAT_TRIGGER_ENABLED = 'automation.repeat.trigger.enabled',
  AUTOMATION_QUOTA_USAGE_ENABLED = 'automation.quota.usage.enabled',
  WORKFLOW_RUN_HISTORY_ENABLED = 'workflow.run_history.enabled',
  QUICKFLOWS_ENABLED = 'freestyle.quickflows.enabled',
  // COMMON DASHBOARD
  DASHBOARDS_ENABLED = 'dashboards.enabled',
  DASHBOARD_HISTORICAL_ENABLED = 'dashboard.historical.enabled',
  DASHBOARD_AIRWATCH_HISTORICAL_DEPRECATION_ENABLED = 'dashboard.historical.airwatch.deprecation.enabled',
  // WS1 SKU
  DASHBOARD_DEFAULT_ENABLED = 'dashboard.default.enabled',
  // WS1 Integration Dashboard
  DASHBOARD_INTEGRATIONS_ENABLED = 'dashboard.integrations.enabled',
  // WS1 INTELLIGENCE SKU
  DASHBOARD_APP_ADOPTION_ENABLED = 'dashboard.app.adoption.enabled',
  DASHBOARD_ENROLLMENT_ENABLED = 'dashboard.enrollment.enabled',
  DASHBOARD_DEVICE_RISK_ENABLED = 'dashboard.device.risk.enabled',
  DASHBOARD_LOGIN_RISK_ENABLED = 'dashboard.login.risk.enabled',
  DASHBOARD_USER_RISK_ENABLED = 'dashboard.user.risk.enabled',
  DASHBOARD_SECURITY_ENABLED = 'dashboard.security.enabled',
  DASHBOARD_DEVICE_PROFILE_EXPERIENCE_ENABLED = 'dashboard.device.profile.experience.enabled',
  DASHBOARD_DEVICE_PROFILE_EXPERIENCE_GPU_ENABLED = 'dashboard.device.profile.experience.gpu.enabled',
  DASHBOARD_DEVICE_PROFILE_EXPERIENCE_MEMORY_ENABLED = 'dashboard.device.profile.experience.memory.enabled',
  DASHBOARD_DEVICE_PROFILE_DEVICE_CRUMB_ENABLED = 'dashboard.device.profile.timeline.crumb.enabled',
  DASHBOARD_DEVICE_PROFILE_OVERVIEW_ENABLED = 'dashboard.device.profile.overview.enabled',
  DASHBOARD_DEVICE_PROFILE_SECURITY_ENABLED = 'dashboard.device.profile.security.enabled',
  DASHBOARD_DEVICE_PROFILE_TIMELINE_V2_ENABLED = 'dashboard.device.profile.timeline.v2.enabled',
  DASHBOARD_DEVICES_ENABLED = 'dashboard.devices.enabled',
  DASHBOARD_DEVICE_DESKTOP_NETWORK_ENABLED = 'dashboard.devices.desktop.network.enabled',
  DASHBOARD_FRONTLINE_ENABLED = 'dashboard.frontline.enabled',
  DASHBOARD_HORIZON_ENABLED = 'dashboard.horizon.enabled',
  DASHBOARD_HORIZON_TITAN_ENABLED = 'dashboard.horizon.titan.enabled',
  DASHBOARD_WIDGET_AGGREGATION_RESULT_FILTER_ENABLED = 'dashboard.widget.aggregation.result.filter.enabled',
  DASHBOARD_WIDGET_COLOR_SCHEMA_PREFERENCE_ENABLED = 'dashboard.widget.color.schema.preference.enabled',

  // Custom Widgets
  WIDGET_OVERLAYS_ENABLED = 'dashboard.widget.overlays.enabled',
  DASHBOARD_WIDGETS_JOIN_ENABLED = 'dashboard.widgets.join.enabled',

  // Apteligent
  APTELIGENT_ENABLED = 'integration.apteligent.enabled',
  APTELIGENT_USER_FLOWS_ENABLED = 'integration.apteligent.user_flow.dashboard.enabled',
  APTELIGENT_NETWORK_INSIGHTS_DASHBOARD_ENABLED = 'integration.apteligent.network_insights.dashboard.enabled',
  APTELIGENT_ERRORS_DASHBOARD_ENABLED = 'integration.apteligent.errors.dashboard.enabled',
  APTELIGENT_HE_DASHBOARD_ENABLED = 'integration.apteligent.handled_exception.dashboard.enabled',
  APTELIGENT_PE_DASHBOARD_ENABLED = 'integration.apteligent.plugin_exception.dashboard.enabled',
  APTELIGENT_PRODUCTIVITY_APPS_ENABLED = 'apteligent.productivity.apps.enabled',
  APTELIGENT_USERS_ENABLED = 'integration.apteligent.users.dashboard.enabled',

  // Employee Experience
  EMPLOYEE_EXPERIENCE_ENABLED = 'integration.employee_experience.enabled',
  EMPLOYEE_EXPERIENCE_APPS_ENABLED = 'integration.employee_experience.apps.dashboard.enabled',
  EMPLOYEE_EXPERIENCE_APPS_UX_SCORE_ENABLED = 'integration.employee_experience.apps.dashboard.ux_score.enabled',
  EMPLOYEE_EXPERIENCE_APP_DETAILS_ENABLED = 'global.search.employee_experience.apps.enabled',
  EMPLOYEE_EXPERIENCE_DEVICE_DETAILS_ENABLED = 'global.search.devices.enabled',
  APTELIGENT_DEVICE_DETAILS_ENABLED = 'global.search.sdk.devices.enabled',

  // User Profile
  USER_PROFILE_ENABLED = 'global.search.users.enabled',
  USER_PROFILE_EXPERIENCE_ENABLED = 'dashboard.user.profile.experience.enabled',
  USER_PROFILE_SECURITY_ENABLED = 'dashboard.user.profile.security.enabled',

  // Multi Subscription
  MULTI_SUBSCRIPTIONS_ENABLED = 'intelligence.multi_subscriptions.enabled',

  // Integration auto optin
  INTEGRATION_AUTO_OPTIN = 'integration.auto.optin.enabled',

  // Preview
  PREVIEW_ENABLED = 'preview.enabled',

  // Product Feedback
  PRODUCT_FEEDBACK_ENABLED = 'product.feedback.enabled',

  // UEM
  UEM_ENABLED = 'integration.uem.enabled',
  AIRWATCH_ENABLED = 'integration.airwatch.enabled',
  AIRWATCH_USERRISKSCORE_ENABLED = 'integration.airwatch.userriskscore.enabled',
  // vIDM
  IDM_ENABLED = 'integration.idm.enabled',
  // Horizon
  HORIZON_ENABLED = 'integration.horizon.enabled',
  // Hub
  HUB_ENABLED = 'integration.hub.enabled',
  // UAG
  INTEGRATION_UAG_ENABLED = 'integration.uag.enabled',
  // Assist
  ASSIST_ENABLED = 'integration.assist.enabled',
  // ServiceNow ITSM
  SERVICE_NOW_ITSM_ENABLED = 'integration.servicenow_itsm.enabled',

  // Trust Network
  TRUST_NETWORK_ENABLED = 'trust.network.enabled',
  BETTERMOBILE_ENABLED = 'integration.bettermobile.enabled',
  CARBONBLACK_ENABLED = 'integration.carbonblack.enabled',
  CARBONBLACK_UPGRADE_REQUIRED = 'carbonblack.integration.upgrade.required',
  CHECKPOINT_ENABLED = 'integration.checkpoint.enabled',
  CROWDSTRIKE_ENABLED = 'integration.crowdstrike.enabled',
  DELL_ENABLED = 'integration.dell.enabled',
  EXTREME_NETWORKS_ENABLED = 'integration.extreme_networks.enabled',
  LOOKOUT_ENABLED = 'integration.lookout.enabled',
  NETSKOPE_ENABLED = 'integration.netskope.enabled',
  PRADEO_ENABLED = 'integration.pradeo.enabled',
  WANDERA_ENABLED = 'integration.wandera.enabled',
  WS_ONE_MTD_ENABLED = 'integration.workspace_one_mobile_threat_defense.enabled',
  WS_ONE_MTD_MULTI_TENANCY_ENABLED = 'integration.workspace_one_mobile_threat_defense.inbound.multi_tenancy.enabled',
  ZIMPERIUM_ENABLED = 'integration.zimperium.enabled',
  ZOOM_ENABLED = 'integration.zoom.enabled',

  // Microsoft
  MICROSOFT_ENABLED = 'integration.microsoft.enabled',

  // Trust Network Event Forwarder
  EVENT_FORWARDER_ENABLED = 'event.forwarder.enabled',

  // CVE Vulnerabilities
  CVE_VULNERABILITIES = 'cve.vulnerabilities.enabled',
  DASHBOARD_CVE_VULNERABILITIES = 'dashboard.cve.vulnerabilities.enabled',

  // Global Search
  GLOBAL_SEARCH_ENABLED = 'global.search.enabled',

  // RBAC
  RBAC_ENABLED = 'rbac.enabled',
  AZURE_AD_ENABLED = 'settings.azuread.setup.enabled',

  // Standalone
  STANDALONE_APTELIGENT_ENABLED = 'standalone.apteligent.enabled',

  // Reports
  REPORT_ENABLED = 'report.enabled',
  REPORT_RETENTION_ENABLED = 'report.retention.enabled',
  TIMESERIES_REPORT_ENABLED = 'timeseries.report.enabled',
  REPORT_SHARING_ACCESS_POLICIES_ENABLED = 'report.internal.sharing.accesspolicies.enabled',
  REPORT_FORMAT_OPTION_ENABLED = 'report.format.option.enabled',
  REPORT_CSV_FRIENDLY_COLUMN_LABELS_OPTION_ENABLED = 'report.csv.friendly.column.labels.option.enabled',
  REPORT_BUILDER_V2 = 'ui.report.builder.v2.enabled',
  REPORT_PUBLIC_SHARING_ENABLED = 'report.external.sharing.enabled',

  // Service Accounts
  SERVICE_ACCOUNTS_ENABLED = 'settings.service.accounts.enabled',

  // Solution
  DESKTOP_ADVANCED_TELEMETRY_ENABLED = 'integration.employee_experience.card.enabled',
  MOBILE_ADVANCED_TELEMETRY_ENABLED = 'integration.apteligent.card.enabled',
  SOLUTIONS_ENABLED = 'solutions.enabled',
  SOLUTIONS_CVE_ENABLED = 'solutions.cve.enabled',
  SOLUTIONS_CVE_IOS_ENABLED = 'solutions.cve.ios.enabled',
  SOLUTIONS_CVE_MACOS_ENABLED = 'solutions.cve.macos.enabled',
  SOLUTIONS_DEEM_PHYSICAL_ENABLED = 'solutions.deem.physical.enabled',
  SOLUTIONS_DEEM_VIRTUAL_INFRA_DASHBOARD_ENABLED = 'solutions.deem.virtual.infra_dashboard.enabled',
  SOLUTIONS_DEEM_VIRTUAL_SESSION_TIMELINE_ENABLED = 'solutions.deem.virtual.session_timeline.enabled',
  SOLUTIONS_DEEM_VIRTUAL_ENABLED = 'solutions.deem.virtual.enabled',
  SOLUTIONS_DEEM_VIRTUAL_GPO_LOAD_ENABLED = 'solutions.deem.virtual.gpo_load.enabled',
  SOLUTIONS_DEEM_HUB_NOTIFICATIONS = 'solutions.deem.hub.notifications.enabled',
  SOLUTIONS_DEEM_INCIDENT_ENABLED = 'solutions.deem.incident.enabled',
  SOLUTIONS_DEEM_INSIGHTS_ENABLED = 'solutions.deem.insights.enabled',
  SOLUTIONS_DEEM_INSIGHT_TO_INCIDENT = 'ui.insights.incident.enabled',
  SOLUTIONS_DEEM_RCA_ENABLED = 'solutions.deem.rca.enabled',
  SOLUTIONS_DEEM_SURVEYS_ENABLED = 'solutions.deem.surveys.enabled',
  SOLUTIONS_PLAYBOOKS_ENABLED = 'solutions.playbooks.enabled',
  SOLUTIONS_DEEM_SYNTHETIC_URL_MONITORING_ENABLED = 'integration.employee_experience.synthetic_url_monitoring.enabled',
  SOLUTIONS_DEEM_ZOOM_DASHBOARD_ENABLED = 'integration.zoom.quality_of_service.dashboard.enabled',
  SOLUTIONS_DEEM_SAM_ENABLED = 'solutions.deem.sam.enabled',
  SOLUTIONS_ORGANIZATION_OVERVIEW_ENABLED = 'ui.solutions.organization.overview.enabled',
  SOLUTIONS_DEEM_VIRTUAL_RDSH_APP_SUPPORT_ENABLED = 'solutions.deem.virtual.rdsh_app_support.enabled',
  SOLUTIONS_DEEM_VIRTUAL_APP_VOLUMES_ENABLED = 'solutions.deem.virtual.app_volumes.enabled',

  // Bookmarks
  USER_BOOKMARKS_ENABLED = 'intelligence.user.shortcuts.enabled',

  // Data Access Policy
  DATA_ACCESS_POLICY_ENABLED = 'data.segmentation.enabled',

  // Workspace ONE Cloud Feature Flags

  // Workspace ONE Cloud
  WORKSPACE_ONE_CLOUD_ENABLED = 'ws1c.first.time.experience.enabled',

  // Workspace ONE Federation
  WORKSPACE_ONE_FEDERATION_ENABLED = 'ws1c.federation.enabled',

  // CSP Federation Setup
  CSP_FEDERATION_SETUP_ENABLED = 'ws1c.csp.federation.setup.enabled',

  // Silent Opt In
  INTEGRATION_SILENT_OPTIN_ENABLED = 'ws1c.integration.silentoptin.enabled',

  // Silent Opt In for Hub
  INTEGRATION_HUB_SILENT_OPTIN_ENABLED = 'ws1c.integration.hub.silentoptin.enabled',

  // Access Rename Tenant
  ACCESS_RENAME_TENANT_ENABLED = 'ws1c.access.rename_tenant.enabled',

  // Trial Questionnaire
  PRODUCT_TRIAL_QUESTIONNAIRE_ENABLED = 'ws1c.product.trial_questionnaire.enabled',

  // Workspace ONE RBAC
  WORKSPACE_ONE_RBAC_ENABLED = 'ws1c.rbac.enabled',
  WORKSPACE_ONE_ACCESS_RBAC_ENABLED = 'ws1c.access.rbac.enabled',
  WORKSPACE_ONE_BROWNFIELD_RBAC_ENABLED = 'ws1c.brownfield.rbac.enabled',
  WORKSPACE_ONE_HORIZON_RBAC_ENABLED = 'ws1c.horizon.rbac.enabled',
  WORKSPACE_ONE_HUBSERVICES_RBAC_ENABLED = 'ws1c.hubservices.rbac.enabled',
  WORKSPACE_ONE_UEM_RBAC_ENABLED = 'ws1c.uem.rbac.enabled',

  // Titan (Horizon V2) Enabled
  WORKSPACE_ONE_TITAN_INTEGRATION_ENABLED = 'ws1c.titan.integration.enabled',

  // Pendo
  PENDO_ADMIN_PREFERENCES_ENABLED = 'ui.telemetry.pendo.admin_preferences.enabled',
  PENDO_TELEMETRY_ENABLED = 'ui.telemetry.pendo.enabled',

  // Nested Groups filter
  NESTED_GROUPS_FILTER_ENABLED = 'ui.nested_groups_filter.enabled',

  // Global Orchestrator
  GLOBAL_ORCHESTRATOR_GROUPS_ENABLED = 'global.orchestrator.groups.enabled',
  GLOBAL_ORCHESTRATOR_CONDITIONS_ENABLED = 'global.orchestrator.conditions.enabled',

  // System Limits Enabled
  SYSTEM_LIMITS_ENABLED = 'ui.servicelimits.enabled',

  // NOTIFICATION
  NOTIFICATION_ENABLED = 'ws1c.notification.enabled',
  NOTIFICATION_RULES_ENABLED = 'ws1c.notification.rules.enabled',

  // IA V2
  IA_V2_PHASE2_ENABLED = 'ui.ia.v2.phase2.enabled',

  // WS1C Broker Integration
  WS1_BROKER_SERVICE_OPTIN_FLOW_ENABLED = 'ws1c.broker.service.optin_flow.enabled',
  WS1_BROKER_BROWNFIELD_MIGRATION_ENABLED = 'ws1c.broker.brownfield_migration.enabled',

  // WS1 Brownfield Notification Experience
  WORKSPACE_ONE_BROWNFIELD_NOTIFICATION_EXPERIENCE_ENABLED = 'ws1c.brownfield.notification_experience.enabled',
  WS1C_BROWNFILED_LIMITED_AVAILIBILITY_ENABLED = 'ws1c.brownfield.limited_availability.enabled',

  // Scripts, Sensors and UEM Freestyle Workflows in Marketplace
  MARKETPLACE_SCRIPTS_ENABLED = 'marketplace.scripts.enabled',
  MARKETPLACE_SENSORS_ENABLED = 'marketplace.sensors.enabled',
  MARKETPLACE_UEM_FREESTYLE_WORKFLOWS_ENABLED = 'marketplace.uem.freestyle.workflows.enabled',

  // Custom Role
  CUSTOM_ROLE_ENABLED = 'custom.role.enabled',

  // Attribute selector v2
  ATTRIBUTE_SELECTOR_V2 = 'ui.attribute.selector.v2.enabled',

  // In-product trial
  IN_PRODUCT_TRIAL_ENABLED = 'in_product.trial.enabled',

  // Data Explorer
  UI_DATA_EXPLORER_ENABLED = 'ui.data.explorer.enabled',

  // UEM Environment manager service link
  UI_UEM_ENVIRONMENT_MANAGE_SERVICE_LINK_ENABLED = 'ui.uem.enviroment_manager.service_link.enabled',

  // UEM V2 toggle for standard dashboard
  UI_UEM_V2_DASHBOARD_TOGGLE_ENABLED = 'ui.uem.v2.dashboard.toggle.enabled',

  // Incremental Loading
  WIDGETS_INCREMENTAL_LOADING_ENABLED = 'ui.widgets.incremental_loading.enabled',
}
