/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { unsubscribe } from '@dpa/ui-common';
import { Subscription } from 'rxjs';

import { AppConfig } from '@ws1c/intelligence-common/constants';
import { AccordionWizardConfig } from '@ws1c/intelligence-common/models';
import { AccordionWizardStepComponent } from './accordion-wizard-step/accordion-wizard-step.component';
import { AccordionWizardService } from './services/accordion-wizard.service';

/**
 * AccordionWizardComponent
 * @export
 * @class AccordionWizardComponent
 * @implements {OnInit}
 * @implements {OnChanges}
 * @implements {AfterContentInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-accordion-wizard',
  templateUrl: 'accordion-wizard.component.html',
  styleUrls: ['accordion-wizard.component.scss'],
})
export class AccordionWizardComponent implements OnInit, OnChanges, AfterContentInit, OnDestroy {
  @Input() public preMountContent?: boolean = false;
  @Input() public accordionWizardConfig: AccordionWizardConfig = new AccordionWizardConfig();
  @Output() public accordionWizardConfigChange = new EventEmitter<AccordionWizardConfig>();

  @ContentChildren(AccordionWizardStepComponent) public accordionStepsQueryList: QueryList<AccordionWizardStepComponent>;
  public accordionSteps: AccordionWizardStepComponent[] = [];
  public subs: Subscription[];

  /**
   * constructor
   * @param {AccordionWizardService} accordionWizardService
   * @param {Renderer2} renderer
   * @memberof AccordionWizardComponent
   */
  constructor(
    private accordionWizardService: AccordionWizardService,
    public renderer: Renderer2,
  ) {}

  /**
   * ngOnInit
   * @memberof AccordionWizardComponent
   */
  public ngOnInit() {
    /**
     * Adding dpa-wizard-open class to the body
     * in order to hide GlobalHeaderSearchComponent
     */
    this.renderer.addClass(document.body, AppConfig.WIZARD_OPEN_CLASS);
  }

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof AccordionWizardComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.accordionWizardConfig) {
      this.accordionWizardService.setAccordionWizardConfig(this.accordionWizardConfig || new AccordionWizardConfig());
    }
  }

  /**
   * ngAfterContentInit
   * @memberof AccordionWizardComponent
   */
  public ngAfterContentInit() {
    this.resetWizardSteps();
    this.subs = [
      this.accordionWizardService.accordionWizardConfig$.subscribe((accordionWizardConfig: AccordionWizardConfig) => {
        this.accordionWizardConfig = accordionWizardConfig;
        this.accordionWizardConfigChange.emit(accordionWizardConfig);
      }),
      this.accordionStepsQueryList.changes.subscribe(() => this.resetWizardSteps()),
    ];
  }

  /**
   * ngOnDestroy
   * @memberof AccordionWizardComponent
   */
  public ngOnDestroy() {
    this.renderer.removeClass(document.body, AppConfig.WIZARD_OPEN_CLASS);
    unsubscribe(this.subs);
  }

  /**
   * resetWizardSteps
   * @memberof AccordionWizardComponent
   */
  public resetWizardSteps() {
    this.accordionSteps = this.accordionStepsQueryList.toArray();
    if (this.accordionSteps.length === 1) {
      this.accordionWizardConfig.openStepIndices = new Set([0]);
    }
    this.accordionSteps.forEach((accordionStep: AccordionWizardStepComponent, index: number) => {
      accordionStep.setStepIndex(index);
    });
    this.accordionWizardService.resetSteps(this.accordionSteps.length);
  }

  /**
   * toggleStepByIndex
   * @param {number} stepIndex
   * @memberof AccordionWizardComponent
   */
  public toggleStepByIndex(stepIndex: number) {
    this.accordionWizardService.toggleStepByIndex(stepIndex);
  }
}
