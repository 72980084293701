/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DataDecorator, DataUnit } from '@dpa/ui-common';

import { I18NService } from '@ws1c/intelligence-common/services/i18n.service';
import { DashboardConfig, Leaderboard } from '@ws1c/intelligence-models';

/**
 * LeaderboardComponent
 * @export
 * @class LeaderboardComponent
 */
@Component({
  selector: 'dpa-leaderboard',
  templateUrl: 'leaderboard.component.html',
  styleUrls: ['leaderboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeaderboardComponent {
  @Input() public leaderboard: Leaderboard;
  @Input() public showBars?: boolean = false;
  @Input() public customColors?: any[] = [];
  @Input() public dataUnit?: DataUnit[] = [undefined, undefined];

  public label: string;
  public readonly color = DashboardConfig.DEFAULT_COLORS[0];
  public readonly DATA_UNIT = DataUnit;
  public readonly DATA_DECORATOR = DataDecorator;

  /**
   * Creates an instance of LeaderboardComponent.
   * @param {I18NService} i18nService
   * @memberof LeaderboardComponent
   */
  constructor(private i18nService: I18NService) {}

  /**
   * getRatiosByColorSet
   * @param {any[]} row
   * @returns {any[]}
   */
  public getRatiosByColorSet(row: any[]): any[] {
    const ratios = [];
    const colorKeys = this.customColors.map((color) => color.name);
    // consider 0 or 1 customColors to be plain bar
    if (colorKeys.length < 2) {
      const ratio = {
        count: row[row.length - 1] || 0,
        color: this.customColors?.[0]?.value || this.color,
        label: this.label,
      };
      ratios.push(ratio);
      return ratios;
    }

    // consider 2 or more customColors to be ratio bar with given rollup data
    return colorKeys.map((key: string) => {
      return {
        count: this.leaderboard.rollup.get(row[0])?.[key] || 0,
        color: this.customColors.filter((colorSet) => colorSet.name === key)?.[0].value,
        label: this.i18nService.translate(`COMMON_MESSAGES.${key.toUpperCase()}`),
      };
    });
  }
}
