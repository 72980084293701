/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { CacheBucket, HttpCacheManager, withCache } from '@ngneat/cashew';

/**
 * HttpCache
 *
 * @export
 * @class HttpCache
 */
@Injectable({
  providedIn: 'root',
})
export class HttpCache {
  // Unique identifiers - acts as keys inside a cache bucket
  public static KEYS = {
    ALL_CONNECTOR_ACTIONS: 'ALL_CONNECTOR_ACTIONS',
    ALL_CONNECTORS_WITH_ACTIONS: 'ALL_CONNECTORS_WITH_ACTIONS',
  } as const;

  // Cache Buckets
  public static BUCKETS = {
    CONNECTORS: new CacheBucket(),
    STANDARD_DASHBOARDS: new CacheBucket(),
    STANDARD_WIDGET_DATA: new CacheBucket(),
  } as const;

  public static cacheManager: HttpCacheManager;

  /**
   * constructor
   * @param {HttpCacheManager} manager
   * @memberof HttpCache
   */
  constructor(private manager: HttpCacheManager) {
    HttpCache.cacheManager = this.manager;
  }

  /**
   * @getContext
   * @param {CacheBucket} bucket
   * @param {CacheKey} cacheIdentifier
   * @returns {HttpContext}
   * @memberof HttpCache
   */
  public static getContext(bucket: CacheBucket, cacheIdentifier?: CacheKey | string): ReturnType<typeof withCache> {
    return withCache({
      bucket,
      key: cacheIdentifier,
    });
  }

  /**
   * clearBucket
   * @param {CacheBucket} bucket
   * @memberof HttpCache
   */
  public static clearBucket(bucket: CacheBucket) {
    HttpCache.cacheManager.delete(bucket);
  }

  /**
   * clear
   * `@HttpCache.clear` - when applied as decorator clears the cache bucket.
   * Cache bucket will be cleared before executing the actual function
   * Do not apply this decorator when cache needs to be cleared on a conditional basis, instead use HttpCache.clearBucket
   * @static
   * @param {CacheBucket} bucket
   * @returns {(target: any, propertyKey: string, descriptor: PropertyDescriptor) => void}
   * @memberof HttpCache
   */
  public static clear(bucket: CacheBucket) {
    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
      const origFn = target[propertyKey];
      descriptor.value = function (...args) {
        HttpCache.cacheManager.delete(bucket);
        return origFn.call(this, ...args);
      };
    };
  }
}

type Keys = keyof typeof HttpCache.KEYS;
type CacheKey = (typeof HttpCache.KEYS)[Keys];
