/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/* eslint-disable no-bitwise */
/**
 * returns hash number of given string
 *
 * @param {string} str
 * @returns {number}
 */
export const hash = (str = ''): number => {
  let code = 5381;
  let i = str.length;

  while (i) {
    code = (code * 33) ^ str.charCodeAt(--i);
  }
  return code >>> 0;
};
